
import CyanPageWrapper, { defineComponent, IonPage } from '@/components/CyanPageWrapper.vue'
import TerritoryPicker from '@/components/TerritoryPicker.vue';
import MapaSv from '@/components/MapaSv.vue';

export default defineComponent({
  name: "PickDepto",
  components: {
    TerritoryPicker,
    CyanPageWrapper,
    IonPage,
    MapaSv
  },
  computed: {},
  methods: {}
});
